<template>
	<!-- 预出库汇总 -->
	<div class="preDeliverySummary">
		<div class="search">
			<div class="search-l">
				<div class="l-item">
					<div class="item-label">配送日期:</div>
					<div class="item-input">
						<el-date-picker v-model="searchForm.date" type="date" placeholder="选择预出库日期"
							value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">团长:</div>
					<div class="item-input">
						<!-- <el-select v-model="searchForm.leader_member_id" filterable placeholder="请选择团长">
                            <el-option v-for="item in customerList" :key="item.member_id" :label="item.name"
                                :value="item.member_id">
                            </el-option>
                        </el-select> -->
						<el-select v-model="searchForm.leader_member_id" filterable remote placeholder="请选择团长"
							:remote-method="remoteMethod" style="width: 100%;">
							<el-option v-for="item in customerList" :key="item.member_id" :label="item.name"
								:value="item.member_id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="loading = true, page = 1, getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.date = getCurrentDate(), getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<span style="color:#F56C6C;padding-left:20px">根据配送日期实时查询同地址同仓库的订单商品总额（含售后）及运费支付总额</span>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="团长姓名" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.leader_name }}</p>
					</template>
				</el-table-column>
				<el-table-column label="团长昵称" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.leader_username }}</p>
					</template>
				</el-table-column>
				<el-table-column label="收货人" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.contacts }}</p>
					</template>
				</el-table-column>
				<el-table-column label="收货人电话" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.phone }}</p>
					</template>
				</el-table-column>
				<el-table-column label="收货地址" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.address }}</p>
					</template>
				</el-table-column>
				<el-table-column label="仓库名称" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.warehouse_name }}</p>
					</template>
				</el-table-column>
				<el-table-column label="配送类型" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">
							{{ scope.row.delivery_type == 1 ? '配送' : '自提' }}
						</p>
					</template>
				</el-table-column>
				<el-table-column label="配送日期" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.arrived_date }}</p>
					</template>
				</el-table-column>
				<el-table-column label="商品总额" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.amount }}</p>
					</template>
				</el-table-column>
				<el-table-column label="已付运费" align="center">
					<template slot-scope="scope">
						<p :style="scope.row.isShowRed ? 'color:red' : ''">{{ scope.row.delivery_fee }}</p>
					</template>
				</el-table-column>
				<el-table-column label="参考运费" align="center">
					<template slot-scope="scope">
						<p v-if="scope.row.supplier_leader_info.is_delivery = 1"
							:style="scope.row.isShowRed ? 'color:red' : ''">配送{{
                                scope.row.supplier_leader_info.delivery_fee
                            }}元,单日满{{ scope.row.supplier_leader_info.free_delivery_total }}元免收</p>
						<p v-else :style="scope.row.isShowRed ? 'color:red' : ''">不配送</p>
					</template>
				</el-table-column>
			</Table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchForm: {
					date: '',
					warehouse_id: ''
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				customerList: [], //团长
				// 仓库
				warehouseOptions: [],
			}
		},
		mounted() {
			this.searchForm.date = this.getCurrentDate()
			// 列表
			this.getList()
			this.getWarehouseList()
			// this.getCustomerList()
		},
		methods: {
			getList() {
				this.$http.post(`erp/v1/preck/pre_generate`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						// amount 比supplier_leader_info.free_delivery_total小，并且delivery_fee=0，表格一行都是红的
						this.tableData.forEach(item => {
							if (item.delivery_type == 1) {
								if (Number(item.amount) < Number(item.supplier_leader_info
										.free_delivery_total) && item.delivery_fee == 0) {
									item.isShowRed = true
								}
							}
						});
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 团长
			remoteMethod(e) {
				this.$http.post(`erp/v1/customer/lst`, {
					pageNum: 100,
					name: e,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.customerList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped></style>